.unlogged--registrations-new-component{
  height: minmax(calc(100vh), auto);
  grid-template-rows: var(--unlogged-bar-top-height) minmax(calc(100vh - var(--unlogged-bar-top-height)), min-content);
  background: linear-gradient(180deg,hsla(0,0%,100%,0), var(--light2-yellow-color) 80%);
}

.unlogged--registrations-new-component > .fr-bottom{
  grid-template-columns: 0.5fr minmax(auto, 600px) 0.5fr;
  grid-template-rows: 20px min-content 60px;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content{
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
  grid-column: 2;
  grid-row: 2;
  background-color: var(--white-color);
  /* background-image: var(--unlogged-lines-icon-string); */
  border-radius: 15px;
  background-position: 0 10%;
  grid-template-rows: 1fr auto 1fr;
  position: relative;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form{
  grid-row: 2;
  grid-template-columns: 1fr;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-left{
  padding: 44px 60px 20px 60px;
  border-right: 1px solid var(--light-grey-color);
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-left
> .title{
  color: var(--light-black-color);
  font-size: 45px;
  font-family: "RobotoBlack";
  padding: 0 0 20px 0;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-left
> .text{
  font-size: 20px;
  color: var(--medium-grey-color);
  line-height: 1.5;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right{
  padding: 0 30px 60px 30px;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> #error_explanation{
  background-color: var(--white-color);
  padding: 10px 20px 4px 20px;
  font-size: 13px;
  color: var(--medium-grey-color);
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> #error_explanation > .item{
  padding: 0 0 6px 0;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fields{
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .field,
.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fields > .field{
  padding: 0 0 20px 0;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .field > .field-txt,
.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fields > .field > .field-txt{
  font-size: 14px;
  padding: 0 0 5px 0;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .field > .txt,
.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fields > .field > .txt,
.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .field > .field_with_errors > .txt,
.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fields > .field > .field_with_errors > .txt{
  border-radius: 5px;
  width: 100%;
  padding: 16px 0 14px 20px;
  border: 1px solid var(--medium-grey-color);
  color: var(--light-black-color);
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fields > .field > .txt-invalid{
  border: 1px solid var(--light2-red-color);
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .actions > .btn{
  padding: 17px 0;
  border-radius: 5px;
  width: 100%;
  font-size: 18px;
  background: var(--violet-color);
  color: var(--white-color);
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .actions > .btn:disabled{
  background: var(--light-grey-color);
  color: var(--medium-grey-color);
  cursor: not-allowed;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .fr-shared{
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .fr-shared > .fr-shared-link{
  color: var(--light-black-color);
  text-align: center;
  padding: 10px 0 0 0;
}

.unlogged--registrations-new-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .fr-shared > .fr-shared-link > .lnk{
  color: var(--light-black-color);
}

